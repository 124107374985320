<template>
  <card :title="$t('series.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('series.updated')" />

      <series-form :form="form"></series-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SeriesForm from "../../accounts/series/form"
import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    "series-form": SeriesForm
  },
  data: () => ({
    form: new Form({
      church_id: null,
      thumbnailUrl: "",
      thumbnail: null,
      title: "",
      description: "",
    })
  }),
  created() {
    const church_id = this.$route.query.church_id;
    this.form['church_id'] = church_id;
  },
  methods: {
    async create() {
      const { data } = await this.form.submit('post','/api/v1/admin/series', {
        // Transform form data to FormData
        transformRequest: [function(data) {
          return objectToFormData(data);
        }]
      });
      
      const series = data.series;

      // Update the store
      this.$store.dispatch("admin/updateSeries", { series: series });

      // Update the form
      this.$router.push({name: 'admin.series.edit', params: {id: series.id}, query: {church_id: series.church_id}})
    }
  }
};
</script>
